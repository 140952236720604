import React, { useEffect } from "react"
import {FiCheck} from "react-icons/fi";
import { getPaddleInstance, initializePaddle } from "@paddle/paddle-js"

import Layout from "../../components/layout";
import {OrderHeader as Header} from "../../components/breadcrumb";
import {KeyHeader} from "../../components/typography";
import {Callout} from "../../components/alert";
import { paddle } from "../../data/puddle"

const pageTitle = "Order";


function openCheckout(edition) {
  const Paddle = getPaddleInstance();
  const priceId = paddle[Paddle.Environment.get()].prices[edition]
  Paddle.Checkout.open({
    items: [{priceId}]
  });
}

const Feature = ({className = '', children}) => (
    <span className={className}><FiCheck className="text-info"/>{' '}{children}</span>
);

const PriceCard = ({title, price, developers, edition, recommended}) => (
    <div className={`card mb-4 shadow ${recommended ? 'border-info' : ''}`}>
        <h6 className="text-center mt-2">{recommended ? 'Recommended' : <>&nbsp;</>}</h6>
        <div className="mb-3 mt-2">
            <h2 className="font-weight-normal text-center">{title}</h2>
        </div>


        <button className={`mb-5 btn ${recommended ? 'btn-info' : 'btn-outline-info'} align-self-center px-5`}
           onClick={() => openCheckout(edition)}
        >
            {`$${price}`}
        </button>

        <hr className="mx-3 my-0"/>

        <div className="card-body text-muted">
            <p>Includes</p>
            <ul className="list-unstyled">
                <li><Feature>Full source code</Feature></li>
                <li><Feature>Email support</Feature></li>
                <li><Feature>No distribution royalties</Feature></li>
                <li><Feature>One year free upgrades</Feature></li>
                <li className="text-dark"><Feature>{developers}</Feature></li>
            </ul>

        </div>
    </div>
);


const Page = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paddleEnv = searchParams.get('paddle_env');
    void initializePaddle({
      token: paddle[paddleEnv ?? "production"].token,
      eventCallback: (e) => {
        console.log(e)
      }
    })
  }, [])

  return (
    <Layout pageTitle={pageTitle}
            pageNav={<Header />}
    >
      <p>
        The SQLAPI++ library is distributed as shareware. You can use trial version for evaluation purposes
        only. The evaluation version has no limits or functional differences from registered
        version.
        However, evaluation version displays a registration message every time an application
        performs
        its first database connection (Windows version). Linux/Unix evaluation version throws trial
        exception approximately once per five successful database connections.
      </p>

      <p>After registration you will:</p>
      <ul>
        <li>receive a non-trial version of the library, including its full source code</li>
        <li>continue to receive free lifetime email support</li>
        <li>get one year of free bug fixing and new version upgrades</li>
      </ul>
      <Callout heading="Bug Fixing and Upgrades">
        The license price includes one year of free bug fixing and updates. You will obtain all new features and
        bug fixes produced within one year at no additional cost.
      </Callout>

      <p>
        You can purchase SQLAPI++ through secure online ordering ShareIt! service. You can
        order SQLAPI++ using credit card, bank transfer, check or cash. After registration
        registered copy of SQLAPI++ will be emailed to you.
      </p>

      <p className="mb-4">
        There are three main packages to choose from. Select the appropriate one based on the number of developers
        that will be using the library.
      </p>
      <div className="card-deck">
        <PriceCard title="Site+"
                   price={897}
                   developers="Unlimited developers"
                   edition="site_plus"
        />
        <PriceCard title="Site"
                   price={598}
                   developers="Up to 10 developers"
                   edition="site"
                   recommended={true}
        />
        <PriceCard title="Personal"
                   price={299}
                   developers="Single developer"
                   edition="personal"
        />
      </div>

        <KeyHeader id="upgrade-extend">Upgrade Your License or Extend Upgrades Period</KeyHeader>
        <p>
            If you are a registered user you can upgrade your current licence (to Site or Site+).
            Email {' '}<a href="mailto:order@sqlapi.com">order@sqlapi.com</a>{' '} for details.
        </p>
        <p>
            If you need to extend the 1 year period for new version upgrades you can do it
            {' '}<button role="link"
                         className="btn btn-link p-0"
                         style={{display: 'contents'}}
                         onClick={() => openCheckout('extend_one_year')}>here</button>.
        </p>
    </Layout>
  )
};

export default Page;
