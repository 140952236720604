const paddle = {
  sandbox: {
    token: 'test_38730ced49733935602d95fab7d',
    prices: {
      personal: 'pri_01jjtfh3q2rgwz6vwa16wp181m',
      site: 'pri_01jjtfrb20qm45m09cth1fq08m',
      site_plus: 'pri_01jjtfxg3fm18ceaszzbrrbssa'
    }
  },
  production: {
    token: 'live_e8a57ee6546484c20eabc9a8ad6',
    prices: {
      personal: 'pri_01jk3nrtyqrv4mbpvsswg3yrer',
      site: 'pri_01jk3p19ex9mwz6n35ser1kxsy',
      site_plus: 'pri_01jk3p5jp9g6yydsamzpy4hya8',
      extend_one_year: 'pri_01jk8cxw2ebbht393dshepm0pp'
    }
  }
};

export {
  paddle
};
